import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import TopBunerProduct from '../../components/top-buner/top-baner-product';
import OfficeAtMap from '../../components/office-on-map/office-on-map';
import ImagesView from '../../components/images-view/images-view';
import FormQuickOrder from '../../components/form-quick-oreder/form-quick-order';
import TexelGoatfeature from '../../images/sheeps/texel_poroda_ovec/texel-feature.jpg'
import TexelGoatGallery1 from '../../images/sheeps/texel_poroda_ovec/ovca-texel.jpg'
import TexelGoatGallery2 from '../../images/sheeps/texel_poroda_ovec/ovci-texel.jpg'
import TexelGoatGallery3 from '../../images/sheeps/texel_poroda_ovec/pemennie-texel.jpg'
import TexelGoatGallery4 from '../../images/sheeps/texel_poroda_ovec/opisanie-texel-porody.jpg'


const TexelePoroda = (props) => {
  const breadCramb = [{name:'Главная', url:'/', status: false}, 
    {name:'Описание пророды овец Тексель', url:'#', status:true}]

    const indicators = [
      
  ]

    const galleryImage = [
      {
        src: TexelGoatGallery1,
        width: 3,
        height: 2,
        alt: 'Тексель порода овец',
        title: 'Тексель порода овец'
      },
      {
        src: TexelGoatGallery2,
        width: 3,
        height: 2,
        alt: 'Тексель порода овец',
        title: 'Тексель порода овец'
      },
      {
        src: TexelGoatGallery3,
        width: 3,
        height: 2,
        alt: 'Тексель порода овец',
        title: 'Тексель порода овец'
      },
      {
        src: TexelGoatGallery4,
        width: 3,
        height: 2,
        alt: 'Тексель порода овец',
        title: 'Тексель порода овец'
      },
    ];
  return (
    <div>
        <SEO title="Тексель Порода Овец: описание породы, цена, фото"
            description="Порода была выведена на острове Тексель, расположенного у северного побережья Нидерландов.">                          
      </SEO>
      <Layout>
      <TopBunerProduct TopImage={''}  
                      titileH1={'Описание пророды овец Тексель'}
                      comment={""}
                      FormView={true}
                      LogoView={false}
                      typeofBunner={'descrition-page'}
                      breadCrumbs={breadCramb}
                      indicators={indicators}>
            </TopBunerProduct>
        <Container fluid>
         <Row className="mt-5">
              <Col md="6" xs='12' className="text-justify ">
                <div className="pl-md-5">
                    <h1 className="text-center text-md-left ">Порода овец Тексель</h1>
                    <p className="text-justify">
                    Порода была выведена на острове Тексель, расположенного у северного побережья Нидерландов. Точное происхождение породы не известно, 
                    но можно предположить что это кросс нескольких английских пород Свои выдающиеся мясные качества тексель получил 
                    в течении длительного периода выведения породы. Акцент был сделан на овцу, которая давала бы ягнят с хорошими мясными качествами.
                    </p>
              
                  <p className="text-justify">
                  Поскольку основным рынком сбыта была Континентальная Европа, где избыток жира в прослойках мяса был всегда не популярен, 
                  были предприняты специальные усилия для выведения овец с низкой склонностью к отложению жира. В наше время порода 
                  становится все популярней и популярней. Производство шерсти постепенно снижается и место шерстных пород занимают мясные. 
                  Тексельэто одна из самых распространенных мясных пород в мире.
                  </p>
                </div>
              </Col>
              <Col md="6" xs='12' className="text-center">
                <img src={TexelGoatfeature} className="w-100" style={{maxWidth:"500px"}}  
                                                            alt="Фото пророды овец Тексель" 
                                                            title="Фото пророды овец Тексель" />
              </Col>
            </Row>
        </Container>
        
         <Container fluid className="mt-3">
           <Row>
             <Col md="6" sm="12" className="pl-md-5">
              <p className="text-justify">
              Только в Нидерландах Тексель составляет 70% всего поголовья мясных овец. Тексель бывает двух типов: белый и голубой. 
              Шерсть на голове и ногах очень короткая. У текселя очень характерная «бульдожья морда» - широкая морда с черным носом 
              и сильно разведенными короткими ушами. У них черные копыта. Шерсть средней толщины (46’s – 56’s). Самой выдающийся 
              характеристикой Текселя является его замечательное не жирное мясо. По сравнению с другими породами именно у текселя 
              самое низкое содержание жира в туше, в особенности, в мясной прослойке.
              </p>
            </Col>
            <Col md="6" sm="12" className="pl-md-5">
              <p className="text-justify" className="text-justify">
              Голубой Тексель был впервые зарегистрирован в начале 1970-х в Голландии, когда голубой ягненок родился от белых родителей. 
              После этого порода разделилась и стали сознательно разводить голубых текселей. Эти животные несколько мельче белых, 
              шерсть на голове и ногах черная, по спине светло-голубая, на животе и штанах темнее. Из-за хорошей молочной продуктивности 
              маток ягнята породы Тексель быстро растут и набирают вес. Среднесуточный привес 250 г, при отъеме (12 недель) средний 
              вес ягнят составляет 25 кг, а убойный средний вес 44 кг в возрасте 24 недели.
              </p>
            </Col>
           </Row>
         </Container>
        <Container fluid className="mt-3">
          <h2 className="text-center">Фото овец породы Тексель</h2>
          <ImagesView galleryImage={galleryImage} />
        </Container>
        <OfficeAtMap />
      </Layout>
      
    </div>
  );
};

export default TexelePoroda;